import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import Lowmenu from './lowmenu';
import Listmenu from './listmenu';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
import Slider from "react-slick";
var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            visible: false,
            slotlist: [],
            recentlist: [],
            rewardlist: [],
            newgamelist: [],
            linelink: "",
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        console.log(44, props);
    }

    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }
    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }



    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    async componentDidMount() {
        // console.log(1111111111111111111111111111, this.props.tblcatagoryx[0].System)
        // if( this.props.tblcatagoryx != null)
        // {
        //     this.setState({
        //         System: this.props.tblcatagoryx[0].System
        //     });
        // }

        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                    System: res.data.tblcatagoryx[0].System,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var Username;
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/tabgamelistpop", {
            state: "2",
            System: Systems,
            Type: 1
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                    // console.log(242, res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        slotlist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "7",
            System: Systems,
            Type: 1
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        newgamelist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        await instance.post("/api/v1/tabgamelistpop", {
            state: "5",
            System: Systems,
            playerid: Username

        })
            .then((res) => {
                if (res.data.status === 200) {

                    // this.state.recentmenu
                    this.setState({
                        recentlist: res.data.message,
                        recentmenu: true
                    });
                    //////console.log(555, res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await this.checklogin();
    }
    checkloginx() {
        Swal.fire({
            title: 'Warning!',
            text: 'คุณยังไม่ได้ Login',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
        })
    }
    render() {
        const { loginstate } = this.state
        return (
            <>
                <div className="x-special-menu-entry-wrapper js-special-menu-entry-wrapper">
                    <div className="container">
                        <div className="x-special-menu-entry -main-container">
                            <ul className="nav -nav">
                                <li className="nav-item">
                                    <button
                                        className="nav-link -menu-social-share animated fadeInUp"
                                        data-toggle="modal"
                                        data-target="#loginModal"
                                        data-dismiss="modal"
                                        data-animatable="fadeInUp"
                                        data-delay={50}
                                    >
                                        <img
                                            alt="Website name Image"
                                            className="-bg"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-item-bg.png?v=1"
                                        />
                                        <img
                                            alt="Website name Image"
                                            className="-text-img img-fluid"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-social-share.png?v=1"
                                        />
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className="nav-link -menu-bind-social animated fadeInUp"
                                        data-toggle="modal"
                                        data-target="#loginModal"
                                        data-dismiss="modal"
                                        data-animatable="fadeInUp"
                                        data-delay={100}
                                    >
                                        <img
                                            alt="Website name Image"
                                            className="-bg"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-item-bg.png?v=1"
                                        />
                                        <img
                                            alt="Website name Image"
                                            className="-text-img img-fluid"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-bind-social.png?v=1"
                                        />
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="/#"
                                        className="nav-link -menu-invitation animated fadeInUp"
                                        data-animatable="fadeInUp"
                                        data-delay={150}
                                    >
                                        <img
                                            alt="Website name Image"
                                            className="-bg"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-item-bg.png?v=1"
                                        />
                                        <img
                                            alt="Website name Image"
                                            className="-text-img img-fluid"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-invitation.png?v=1"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href={this.state.linelink}
                                        className="nav-link -menu-birth-date animated fadeInUp"
                                        target="_blank"
                                        rel="nofollow noopener"
                                        data-animatable="fadeInUp"
                                        data-delay={200}
                                    >
                                        <img
                                            alt="Website name Image"
                                            className="-bg"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-item-bg.png?v=1"
                                        />
                                        <img
                                            alt="Website name Image"
                                            className="-text-img img-fluid"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-happy-birth-day.png?v=1"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="#"
                                        className="nav-link -menu-movie animated fadeInUp"
                                        target="_blank"
                                        rel="nofollow noopener"
                                        data-animatable="fadeInUp"
                                        data-delay={250}
                                    >
                                        <img
                                            alt="Website name Image"
                                            className="-bg"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-item-bg.png?v=1"
                                        />
                                        <img
                                            alt="Website name Image"
                                            className="-text-img img-fluid"
                                            src="/build/web/ez-bet-v2/img/special-menu-entry-movie.png?v=1"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="js-replace-cover-seo-container">
                    <div className="x-homepage-banner-container x-banner-slide-wrapper " style={{ marginTop: "15px" }}>
                        <Slider {...settings}>
                            <div
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/1.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/2.jpg"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/3.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/4.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/5.jpg"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/6.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                        </Slider>

                    </div>
                </div>
                <br></br>
                <div className="x-category-total-game -v2" style={{ textAlign: "center" }}>
                    <nav className="nav-menu" id="navbarCategory" style={{ textAlign: "center" }} >
                        <ul className="-menu-parent navbar-nav flex-row  centerdiv" >
                            {/* <li
                                className="-list-parent nav-item px-lg-2 -category-popular animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={50}
                            >
                                <a
                                    href="/listgameall/gamehit/"
                                    className="x-category-button -category-popular -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-popular.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-popular.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-popular.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-popular-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-popular-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-popular-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">ยอดนิยม</span>
                                </a>
                            </li> */}
                            {/* <li
                                className="-list-parent nav-item px-lg-2 -category-new-released animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={100}
                            >
                                <a
                                    href="/listgameall/newgame/"
                                    className="x-category-button -category-new-released -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-new-released.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-new-released.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-new-released.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-new-released-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-new-released-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-new-released-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">เกมใหม่</span>
                                </a>
                            </li> */}
                            <li
                                className="-list-parent nav-item px-lg-2 -category-brand animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={150}
                            >
                                <a
                                    href="/listgameall/slot/"
                                    className="x-category-button -category-brand -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-brand.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-brand.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-brand.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-brand-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-brand-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-brand-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">สล๊อต</span>
                                </a>
                            </li>
                            {/* <li
                                className="-list-parent nav-item px-lg-2 -category-lotto animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={200}
                            >
                                <a
                                    href="/listgameall/lotto/"
                                    className="x-category-button -category-lotto -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-lotto.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-lotto.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-lotto.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-lotto-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-lotto-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-lotto-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">หวย</span>
                                </a>
                            </li> */}
                            <li
                                className="-list-parent nav-item px-lg-2 -category-casino animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={250}
                            >
                                <a
                                    href="/listgameall/casino/"
                                    className="x-category-button -category-casino -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-casino.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-casino.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-casino.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-casino-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-casino-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-casino-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">คาสิโนสด</span>
                                </a>
                            </li>
                            {/* <li
                                className="-list-parent nav-item px-lg-2 -category-skill-game animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={300}
                            >
                                <a
                                    href="/listgameall/slot/"
                                    className="x-category-button -category-skill-game -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-skill-game.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-skill-game.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-skill-game.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-skill-game-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-skill-game-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-skill-game-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">สกิลเกมส์</span>
                                </a>
                            </li> */}
                            <li
                                className="-list-parent nav-item px-lg-2 -category-fishing-game animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={350}
                            >
                                <a
                                    href="/listgameall/fishing/"
                                    className="x-category-button -category-fishing-game -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-fishing-game.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-fishing-game.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-fishing-game.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-fishing-game-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-fishing-game-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-fishing-game-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">ยิงปลา</span>
                                </a>
                            </li>
                            <li
                                className="-list-parent nav-item px-lg-2 -category-sport animated fadeInUp"
                                data-animatable="fadeInUp"
                                data-delay={400}
                            >
                                <a
                                    href="/listgameall/sport/"
                                    className="x-category-button -category-sport -index-page  -category-button-v2 -hoverable "
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-sport.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-sport.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -default"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-sport.png?v=1"
                                        />
                                    </picture>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-sport-hover.png?v=2"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            srcSet="\build\web\ez-bet-v2\img\ez-slot-category-sport-hover.png?v=1"
                                        />
                                        <img
                                            alt=" cover image png"
                                            className="-img -hover"
                                            src="\build\web\ez-bet-v2\img\ez-slot-category-sport-hover.png?v=1"
                                        />
                                    </picture>
                                    <span className="-menu-text-main -text-btn-image">สปอร์ต</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="x-index-content-main-container pb-4 -anon ">

                    <div className="-provider-logo-top">
                        <div className="x-slot-providers-logo-component -index-top ">
                            <div className="-slider-wrapper -single" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:false,&quot;slidesToShow&quot;:1,&quot;slidesToScroll&quot;:1,&quot;fade&quot;:false,&quot;infinite&quot;:true,&quot;autoplay&quot;:true,&quot;autoplaySpeed&quot;:0,&quot;variableWidth&quot;:true,&quot;swipe&quot;:false,&quot;speed&quot;:3000,&quot;pauseOnHover&quot;:false,&quot;cssEase&quot;:&quot;linear&quot;,&quot;lazyLoad&quot;:&quot;ondemand&quot;}">
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-evo-play-1.png" className="-logo -evo-play" alt="GPAY88BET evo-play logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-sp-1.png" className="-logo -sp" alt="GPAY88BET sp logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-ps-1.png" className="-logo -ps" alt="GPAY88BET ps logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-kingmaker-1.png" className="-logo -kingmaker" alt="GPAY88BET kingmaker logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-joker-1.png" className="-logo -joker" alt="GPAY88BET joker logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-habanero-1.png" className="-logo -habanero" alt="GPAY88BET habanero logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-ameba-1.png" className="-logo -ameba" alt="GPAY88BET ameba logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-cq9-1.png" className="-logo -cq9" alt="GPAY88BET cq9 logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-pg-1.png" className="-logo -pg" alt="GPAY88BET pg logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-rich88-1.png" className="-logo -rich88" alt="GPAY88BET rich88 logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-goldy-1.png" className="-logo -goldy" alt="GPAY88BET goldy logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-mg-slot.png" className="-logo -mg-slot" alt="GPAY88BET mg-slot logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-play-tech.png" className="-logo -play-tech" alt="GPAY88BET play-tech logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-pragmatic-play.png" className="-logo -pragmatic-play" alt="GPAY88BET pragmatic-play logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-wm-slot.png" className="-logo -wm-slot" alt="GPAY88BET wm-slot logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-jili.png" className="-logo -jili" alt="GPAY88BET jili logo png" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="-popular-games-list-container" data-animatable="fadeInUp" data-delay={150}>
                        <h2 className="-game-title h5 d-lg-none">Popular Games</h2>
                        <ul className="navbar-nav">
                            <li className="nav-item d-none d-lg-inline-block">
                                <div className="x-game-list-heading-macro -best -big">
                                    <div className="-inner-wrapper">
                                        <img data-src="https://asset.cloudigame.co/build/admin/img/ez-joker/8rqwot18etnuw-vertical.png" src="/build/admin/img/ezs-default-loading-big.png" className="-cover-img lazyload" alt="joker cover image png" />
                                        <div className="-overlay">
                                            <span className="-length">1056 Games</span>
                                            <h2 className="-title">Popular Games</h2>
                                            <a href="/" className="-seemore-btn">All games</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {this.state.categorylist.map((listitem, i) => (
                                <li key={i} id={listitem.gameid} className="nav-item -first">
                                    <a href={"/listgameall/casino/" + listitem.partner}
                                        className="nav-link js-account-approve-aware"
                                        rel="nofollow noopener">
                                        <div className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable" data-status="-cannot-entry -untestable">
                                            <div className="-inner-wrapper">
                                                <div className="x-game-badge-component -hot -big" data-animatable="fadeInUp" data-delay={400}>
                                                    <span>Hot</span>
                                                </div>
                                                <img src={listitem.imagesec} className="-cover-img lazyload img-fluid" alt="pg-soft cover image png" width={249} height={361} />
                                                <div className="-overlay">
                                                    <div className="-overlay-inner">
                                                        <div className="-wrapper-container">
                                                            <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" >
                                                                <i className="fas fa-play" />
                                                                <span className="-text-btn">เข้าเล่น</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-title">{listitem.description}</div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-slot-games-list-container" data-animatable="fadeInUp" data-delay={150}>
                        <h2 className="-game-title h5 d-lg-none">Slot Games</h2>
                        <ul className="navbar-nav">
                            <li className="nav-item d-none d-lg-inline-block">
                                <div className="x-game-list-heading-macro -slot -big">
                                    <div className="-inner-wrapper">
                                        <img data-src="https://asset.cloudigame.co/build/admin/img/ez-joker/8rqwot18etnuw-vertical.png" src="/build/admin/img/ezs-default-loading-big.png" className="-cover-img lazyload" alt="joker cover image png" />
                                        <div className="-overlay">
                                            <span className="-length">653 Games</span>
                                            <h2 className="-title">Slot Games</h2>
                                            <a href="#" className="-seemore-btn">All games</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {this.state.slotlist.map((listitem, i) => (
                                <li key={i} id={listitem.gameid} className="nav-item -first">
                                    <a
                                        href={"/listgameall/slot/" + listitem.partner}
                                        className="nav-link js-account-approve-aware"
                                        rel="nofollow noopener"
                                    >
                                        <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                            <div className="-inner-wrapper">
                                                <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="evo-play cover image png" width={249} height={361} />
                                                <div className="-overlay">
                                                    <div className="-overlay-inner">
                                                        <div className="-wrapper-container">
                                                            <a href={"/listgameall/slot/" + listitem.partner} className="-btn -btn-play">
                                                                <i className="fas fa-play" />
                                                                <span className="-text-btn">เข้า Lobby</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-title">{listitem.description}</div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-new-games-list-container -anon" data-animatable="fadeInUp" data-delay={100}>

                        <h2 className="-game-title h5 d-lg-none">New<br className="d-none d-lg-block" /> Games</h2>
                        <ul className="navbar-nav">
                            <li className="nav-item d-none d-lg-inline-block">
                                <div className="x-game-list-heading-macro -new -big">
                                    <div className="-inner-wrapper">

                                        <img data-src="https://asset.cloudigame.co/build/admin/img/ez-pg-soft/cover-pg-soft-vertical.png" src="/build/admin/img/ezs-default-loading-big.png" className="-cover-img lazyload" alt="pg-soft cover image png" />
                                        <div className="-overlay">
                                            <span className="-length">600 Games</span>
                                            <h2 className="-title">New<br className="d-none d-lg-block" /> Games</h2>
                                            <a href="#" className="-seemore-btn">All games</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {this.state.newgamelist.map((listitem, i) => (
                                <li className="nav-item -first" key={i}>
                                    <a
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                        className="nav-link js-account-approve-aware"
                                        rel="nofollow noopener"
                                    >
                                        <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                            <div className="-inner-wrapper">
                                                <div className="x-game-badge-component -new -normal" data-animatable="fadeInUp" data-delay={400}>
                                                    <span>New</span>
                                                </div>
                                                <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="rich88 cover image png" width={216} height={216} />
                                                <div className="-overlay">
                                                    <div className="-overlay-inner">
                                                        <div className="-wrapper-container">
                                                            <button href={() => false} className="-btn -btn-play js-account-approve-aware">
                                                                <i className="fas fa-play" />
                                                                <span className="-text-btn">เข้าเล่น</span>
                                                            </button>
                                                            <button
                                                                id="loginmode"
                                                                href="#loginModal"
                                                                className="-btn -btn-play js-account-approve-aware"
                                                                data-toggle="modal"
                                                                data-target="#loginModal"
                                                                style={{ display: "none" }}
                                                            >
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-title">{listitem.description}</div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-games-splitter-zone-container -anon" data-animatable="fadeInUp" data-delay={250}>
                        <div className="-left-side">
                            <div className="-live-casino-container" data-animatable="fadeInUp" data-delay={100}>
                                <h2 className="-game-title h5 d-lg-none">Live<br className="d-none d-lg-block" /> Casino</h2>
                                <ul className="navbar-nav">
                                    <li className="nav-item d-none d-lg-inline-block">
                                        <div className="x-game-list-heading-macro -live-casino -normal">
                                            <div className="-inner-wrapper">
                                                <img data-src="https://asset.cloudigame.co/build/admin/img/pretty-gaming/ezs-pretty-gaming-vertical.png" src="/build/admin/img/ezs-default-loading-normal.png" className="-cover-img lazyload" alt="sa-gaming cover image png" />
                                                <div className="-overlay">
                                                    <span className="-length">15 Games</span>
                                                    <h2 className="-title">Live<br className="d-none d-lg-block" /> Casino</h2>
                                                    <a href="casinos.html" className="-seemore-btn">All games</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {this.state.categorylist.slice(0, 5).map((listitem, i) => (
                                        <li className="nav-item -first" key={i}>
                                            <a
                                                href={"/listgameall/slot/" + listitem.partner}
                                                className="nav-link js-account-approve-aware"
                                                rel="nofollow noopener"
                                            >
                                                <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                                    <div className="-inner-wrapper">
                                                        <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="sa-gaming cover image png" width={216} height={216} />
                                                        <div className="-overlay">
                                                            <div className="-overlay-inner">
                                                                <div className="-wrapper-container">
                                                                    <a href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware">
                                                                        <i className="fas fa-play" />
                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-title">{listitem.description}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="-sports-container" data-animatable="fadeInUp" data-delay={100}>
                                <h2 className="-game-title h5 d-lg-none">Sport<br className="d-none d-lg-block" /> Games</h2>
                                <ul className="navbar-nav">
                                    <li className="nav-item d-none d-lg-inline-block">
                                        <div className="x-game-list-heading-macro -sport -normal">
                                            <div className="-inner-wrapper">
                                                <img data-src="https://www.i-pic.info/i/zY8m765513.png" src="/build/admin/img/ezs-default-loading-normal.png" className="-cover-img lazyload" alt="wt-saba cover image png" />
                                                <div className="-overlay">
                                                    <span className="-length">4 Games</span>
                                                    <h2 className="-title">Sport<br className="d-none d-lg-block" /> Games</h2>
                                                    <a href="sports.php" className="-seemore-btn">All games</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {this.state.recentlist.slice(0, 5).map((listitem, i) => (
                                        <li className="nav-item -first" key={i}>
                                            <a
                                                href={"/listgameall/sport/" + listitem.partner}
                                                className="nav-link js-account-approve-aware"
                                                rel="nofollow noopener"
                                            >
                                                <div className="x-game-list-item-macro js-game-list-toggle -normal -cannot-entry -untestable" data-status="-cannot-entry -untestable">
                                                    <div className="-inner-wrapper">
                                                        <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="pinnacle cover image png" width={216} height={216} />
                                                        <div className="-overlay">
                                                            <div className="-overlay-inner">
                                                                <div className="-wrapper-container">
                                                                    <button href={"/listgameall/sport/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" data-toggle="modal" data-target="#loginModal">
                                                                        <i className="fas fa-play" />
                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-title">{listitem.description}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="-right-side">
                            <a href="listgameall/casino/" className="-button-wrapper" data-animatable="fadeInUp" data-delay={100}>
                                <h2 className="text-center">คาสิโนสดชั้นนำ</h2>
                                <img data-src="/build/web/ez-bet/img/ez-slot-girl-china.png" className="-china-girl lazyload" alt="Ezbet china girl image png" />
                                <img data-src="/build/web/ez-bet/img/ez-slot-dice-dual.png" className="-dice-dual lazyload" alt="Ezbet two dices image png" />
                            </a>
                        </div>
                    </div>
                    <div className="-blogs-wrapper">
                        <div className="x-blog-section">
                            <div className="container-fluid">
                                <div className="x-blog-title-header">
                                    <div className="container">
                                        <h2 className="-title">GPAY88 News</h2>
                                    </div>
                                </div>
                                <div className="my-3 mb-lg-4">
                                    <div className="x-blog-category-container">
                                        <div className="-category-wrapper">
                                            {/* <a
                                                href="/article"
                                                className="-btn-category "
                                            >
                                                <picture>
                                                    <img
                                                        alt='รีวิว<br class="d-block d-sm-none">เกมใหม่'
                                                        className="img-fluid -icon-bg"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-1.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <img
                                                        alt='รีวิว<br class="d-block d-sm-none">เกมใหม่'
                                                        className="img-fluid -icon-bg -active"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-1-active.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-text-title">
                                                    รีวิว
                                                    <br className="d-block d-sm-none" />
                                                    เกมใหม่
                                                </span>
                                            </a> */}
                                            {/* <a
                                                href="/listgameall/slot/"
                                                className="-btn-category "
                                            >
                                                <picture>
                                                    <img
                                                        alt='Slot <br class="d-block d-sm-none"> ยอดนิยม'
                                                        className="img-fluid -icon-bg"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-2.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <img
                                                        alt='Slot <br class="d-block d-sm-none"> ยอดนิยม'
                                                        className="img-fluid -icon-bg -active"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-2-active.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-text-title">
                                                    Slot <br className="d-block d-sm-none" /> ยอดนิยม
                                                </span>
                                            </a> */}
                                            <a
                                                href="/listgameall/slot/"
                                                className="-btn-category "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-3.png?v=2"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-3.png?v=1"
                                                    />
                                                    <img
                                                        alt='Jackpot <br class="d-block d-sm-none"> แตก'
                                                        className="img-fluid -icon-bg"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-3.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-3-active.png?v=2"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-3-active.png?v=1"
                                                    />
                                                    <img
                                                        alt='Jackpot <br class="d-block d-sm-none"> แตก'
                                                        className="img-fluid -icon-bg -active"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-3-active.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-text-title">
                                                    Jackpot <br className="d-block d-sm-none" /> แตก
                                                </span>
                                            </a>
                                            <a
                                                href="/listgameall/promotions/"
                                                className="-btn-category "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-4.png?v=2"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-4.png?v=1"
                                                    />
                                                    <img
                                                        alt='กิจกรรม<br class="d-block d-sm-none">มาแรง'
                                                        className="img-fluid -icon-bg"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-4.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-4-active.png?v=2"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="\build\web\ez-bet-v2\img\ez-slot-blog-category-4-active.png?v=1"
                                                    />
                                                    <img
                                                        alt='กิจกรรม<br class="d-block d-sm-none">มาแรง'
                                                        className="img-fluid -icon-bg -active"
                                                        width={180}
                                                        height={60}
                                                        src="\build\web\ez-bet-v2\img\ez-slot-blog-category-4-active.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-text-title">
                                                    กิจกรรม
                                                    <br className="d-block d-sm-none" />
                                                    มาแรง
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div
                                    data-animatable="fadeInUp"
                                    data-offset="200%"
                                    className="animated fadeInUp"
                                >
                                    <div className="x-blog-card-list-container">
                                        <div className="row -list-row">
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a href="งบน้อย-เกมสล็อต.html" className="-card-wrapper">
                                                        <div className="-card-image">
                                                            <img
                                                                alt="งบจำกัด งบน้อย ก็สามารถรวยได้ง่าย ๆ กับ เกมสล็อต"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/0c90a458751c58e3437324a4ca3f44b1.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/0c90a458751c58e3437324a4ca3f44b1.png"
                                                            />
                                                        </div>
                                                        <h6 className="-card-title">
                                                            งบจำกัด งบน้อย ก็สามารถรวยได้ง่าย ๆ กับ เกมสล็อต
                                                        </h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a
                                                        href="สล็อตแตกง่าย,สล็อตเครดิตฟรี.html"
                                                        className="-card-wrapper"
                                                    >
                                                        <div className="-card-image">
                                                            <img
                                                                alt="EZSLOT แหล่งรวมเกมเดิมพันออนไลน์ ครบทุกค่าย"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/526cf9bd7a5d23afdfebb0fb242342fa.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/526cf9bd7a5d23afdfebb0fb242342fa.png"
                                                            />
                                                        </div>
                                                        <h6 className="-card-title">
                                                            EZSLOT แหล่งรวมเกมเดิมพันออนไลน์ ครบทุกค่าย
                                                        </h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a href="ezslot-สล็อต.html" className="-card-wrapper">
                                                        <div className="-card-image">
                                                            <img
                                                                alt="EZSLOT เว็บเกมสล็อตออนไลน์ผู้ผลิตโดยตรงมั่นคงปลอดภัย"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/0ebe39fe9a64348ebd662cc64d859adc.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/0ebe39fe9a64348ebd662cc64d859adc.png"
                                                            />
                                                        </div>
                                                        <h6 className="-card-title">
                                                            EZSLOT เว็บเกมสล็อตออนไลน์ผู้ผลิตโดยตรงมั่นคงปลอดภัย
                                                        </h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a href="ezslot-SLOT.html" className="-card-wrapper">
                                                        <div className="-card-image">
                                                            <img
                                                                alt="EZSLOT เว็บเกมสล็อตออนไลน์ไม่มีขั้นต่ำ"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/4a14012dbec52dc05dcc185d7cf1f9f2.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/4a14012dbec52dc05dcc185d7cf1f9f2.png"
                                                            />
                                                        </div>
                                                        <div className="-card-badge">ข่าวสารทั่วไป</div>
                                                        <h6 className="-card-title">
                                                            EZSLOT เว็บเกมสล็อตออนไลน์ไม่มีขั้นต่ำ
                                                        </h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a href="ปั่น-สล็อต-ในวันนี้.html" className="-card-wrapper">
                                                        <div className="-card-image">
                                                            <img
                                                                alt="ปั่น สล็อต ในวันนี้ พร้อมรับโบนัสฟรีอีกมากมาย"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/bd7c7cec85ab5120ad48a03c7c9d4205.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/bd7c7cec85ab5120ad48a03c7c9d4205.png"
                                                            />
                                                        </div>
                                                        <h6 className="-card-title">
                                                            ปั่น สล็อต ในวันนี้ พร้อมรับโบนัสฟรีอีกมากมาย
                                                        </h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a href="สล็อตแตกง่าย-เล่นสล็อต.html" className="-card-wrapper">
                                                        <div className="-card-image">
                                                            <img
                                                                alt="สล็อตแตกง่าย เล่นสล็อต"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/dd71927c222dd81e4a980c72b1c05457.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/dd71927c222dd81e4a980c72b1c05457.png"
                                                            />
                                                        </div>
                                                        <h6 className="-card-title">สล็อตแตกง่าย เล่นสล็อต</h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a href="game.html" className="-card-wrapper">
                                                        <div className="-card-image">
                                                            <img
                                                                alt="ใครอยากเป็นเซียนเดิมพัน เกมสล็อต ต้องมาอ่านโดยด่วน"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/3c0aeb5c497034fbfc2cacf50d90aca2.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/3c0aeb5c497034fbfc2cacf50d90aca2.png"
                                                            />
                                                        </div>
                                                        <h6 className="-card-title">
                                                            ใครอยากเป็นเซียนเดิมพัน เกมสล็อต ต้องมาอ่านโดยด่วน
                                                        </h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 -list-item">
                                                <div className="x-blog-card-list-item-container">
                                                    <a href="ezslot.html" className="-card-wrapper">
                                                        <div className="-card-image">
                                                            <img
                                                                alt="เว็บสล็อต รวมค่ายสล็อตครบวงจร"
                                                                className="img-fluid -image lazyloaded"
                                                                width={237}
                                                                height={140}
                                                                data-src="https://ezslot.com/media/cache/strip/202306/page-images/7065b5b5de6453c3419d0e6d140bb074.png"
                                                                src="https://ezslot.com/media/cache/strip/202306/page-images/7065b5b5de6453c3419d0e6d140bb074.png"
                                                            />
                                                        </div>
                                                        <h6 className="-card-title">เว็บสล็อต รวมค่ายสล็อตครบวงจร</h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="-all-blog-link-wrapper">
                                                <a href="/blog" className="-all-blog-link">
                                                    ข่าวทั้งหมด
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div
                        className="-promotion-section-wrapper -anon animated fadeInUp"
                        data-animatable="fadeInUp"
                        data-delay={350}
                    >
                        <div className="x-big-win-v3-component">
                            <div>
                                <div className="x-cover x-category-cover-container">
                                    <img
                                        src="/build/web/ez-bet-v2/img/cover-slot-bg.png"
                                        className="-bg"
                                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                                    />
                                    <img
                                        src="https://cdn.javisx.com/image/a8ffe6e2-b1e1-4e96-8c5b-c889f0d460e8.png"
                                        className="-bg -mobile"
                                        alt="ezslot มีมากกว่า แต่ง่ายกว่า เกมเยอะ ภาพสวย ทั้งใหม่"
                                    />
                                    <div className="container-fluid h-100">
                                        <div className="row -contents-wrapper">
                                            <div className="col-12 col-md-6 -left-content" />
                                            <div className="col-12 col-md-6 -right-content">
                                                <div
                                                    data-slickable='{"fade":true,"arrows":false,"autoplay":true,"infinite":true,"pauseOnHover":false,"slidesToShow":1,"autoplaySpeed":3000,"speed":300}'
                                                    data-animatable="fadeInUp"
                                                    data-delay={500}
                                                    className="slick-initialized slick-slider animated fadeInUp"
                                                >
                                                    <div className="slick-list draggable">
                                                        <div className="slick-track" style={{ opacity: 1, width: 2255 }}>
                                                            <div
                                                                className="-inner-heading-wrapper slick-slide"
                                                                data-slick-index={0}
                                                                aria-hidden="true"
                                                                tabIndex={-1}
                                                                style={{
                                                                    width: 451,
                                                                    position: "relative",
                                                                    left: 0,
                                                                    top: 0,
                                                                    zIndex: 998,
                                                                    opacity: 0,
                                                                    transition: "opacity 300ms"
                                                                }}
                                                            >
                                                                <h1 className="-title">
                                                                    Slot Online รวมเกมที่โด่งดัง
                                                                    <br className="d-none d-md-block" />
                                                                    ที่สุดในโลกไว้ที่นี่ รับประกันความมันส์
                                                                </h1>
                                                                <p className="-sub-title">
                                                                    เล่นง่าย จ่ายจริง นักเดิมพัน Slot Online
                                                                    ไม่ควรพลาดร่วมสนุกบน <br className="d-none d-md-block" />{" "}
                                                                    ezslot เปิดให้บริการ 24 ชม.
                                                                </p>
                                                                <div
                                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                    data-animatable="fadeInUp"
                                                                    data-delay={500}
                                                                >
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="btn -register-btn order-0"
                                                                        data-toggle="modal"
                                                                        data-target="#registerModal"
                                                                        tabIndex={-1}
                                                                    >
                                                                        <img
                                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                            className="-btn-bg"
                                                                            alt="EZ Slot สมัครสมาชิก"
                                                                            width={312}
                                                                            height={73}
                                                                        />
                                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="-inner-heading-wrapper slick-slide"
                                                                data-slick-index={1}
                                                                aria-hidden="true"
                                                                tabIndex={-1}
                                                                style={{
                                                                    width: 451,
                                                                    position: "relative",
                                                                    left: "-451px",
                                                                    top: 0,
                                                                    zIndex: 998,
                                                                    opacity: 0,
                                                                    transition: "opacity 300ms"
                                                                }}
                                                            >
                                                                <h2 className="-title">
                                                                    ระบบที่มีประสิทธิภาพสูงสุด รวดเร็ว{" "}
                                                                    <br className="d-none d-md-block" /> สะดวกสบาย
                                                                    เพียงคลิกเดียว
                                                                </h2>
                                                                <p className="-sub-title">
                                                                    ezslot เว็บเดิมพันออนไลน์อันดับ 1
                                                                    ที่มาพร้อมกับระบบที่ยอดเยี่ยมที่สุด
                                                                    <br className="d-none d-md-block" />
                                                                    ของเว็บพนันออนไลน์ทั่วประเทศ
                                                                </p>
                                                                <div
                                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                    data-animatable="fadeInUp"
                                                                    data-delay={500}
                                                                >
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="btn -register-btn order-0"
                                                                        data-toggle="modal"
                                                                        data-target="#registerModal"
                                                                        tabIndex={-1}
                                                                    >
                                                                        <img
                                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                            className="-btn-bg"
                                                                            alt="EZ Slot สมัครสมาชิก"
                                                                            width={312}
                                                                            height={73}
                                                                        />
                                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="-inner-heading-wrapper slick-slide"
                                                                data-slick-index={2}
                                                                aria-hidden="true"
                                                                tabIndex={-1}
                                                                style={{
                                                                    width: 451,
                                                                    position: "relative",
                                                                    left: "-902px",
                                                                    top: 0,
                                                                    zIndex: 998,
                                                                    opacity: 0,
                                                                    transition: "opacity 300ms"
                                                                }}
                                                            >
                                                                <h2 className="-title">
                                                                    รองรับทุกระบบทั้ง มือถือ และคอม{" "}
                                                                    <br className="d-none d-md-block" /> Slot Online
                                                                    เจ้าแรกในไทย
                                                                </h2>
                                                                <p className="-sub-title">
                                                                    ร่วมสนุกกับ slot online ได้ทุกระบบไม่ว่าที่ไหน เมื่อไหร่{" "}
                                                                    <br className="d-none d-md-block" />{" "}
                                                                    ก็สนุกได้เต็มที่มีเพียงอินเตอร์เน็ตอย่างเดียวก็เล่นได้
                                                                </p>
                                                                <div
                                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                    data-animatable="fadeInUp"
                                                                    data-delay={500}
                                                                >
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="btn -register-btn order-0"
                                                                        data-toggle="modal"
                                                                        data-target="#registerModal"
                                                                        tabIndex={-1}
                                                                    >
                                                                        <img
                                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                            className="-btn-bg"
                                                                            alt="EZ Slot สมัครสมาชิก"
                                                                            width={312}
                                                                            height={73}
                                                                        />
                                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="-inner-heading-wrapper slick-slide"
                                                                data-slick-index={3}
                                                                aria-hidden="true"
                                                                tabIndex={-1}
                                                                style={{
                                                                    width: 451,
                                                                    position: "relative",
                                                                    left: "-1353px",
                                                                    top: 0,
                                                                    zIndex: 998,
                                                                    opacity: 0,
                                                                    transition: "opacity 300ms"
                                                                }}
                                                            >
                                                                <h2 className="-title">
                                                                    โปรโมชั่นสล็อตออนไลน์ สุดฮอต{" "}
                                                                    <br className="d-none d-md-block" />{" "}
                                                                    โดนใจนักเดิมพันทั้งเก่าและใหม่
                                                                </h2>
                                                                <p className="-sub-title">
                                                                    เตรียมรับแรงกระแทกกับโปรโมชั่น slot พิเศษกว่าใครที่เติมเต็ม
                                                                    <br className="d-none d-md-block" />
                                                                    แก่นักเดิมพันทั้งเก่าและใหม่
                                                                </p>
                                                                <div
                                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                    data-animatable="fadeInUp"
                                                                    data-delay={500}
                                                                >
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="btn -register-btn order-0"
                                                                        data-toggle="modal"
                                                                        data-target="#registerModal"
                                                                        tabIndex={-1}
                                                                    >
                                                                        <img
                                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                            className="-btn-bg"
                                                                            alt="EZ Slot สมัครสมาชิก"
                                                                            width={312}
                                                                            height={73}
                                                                        />
                                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="-inner-heading-wrapper slick-slide slick-current slick-active"
                                                                data-slick-index={4}
                                                                aria-hidden="false"
                                                                style={{
                                                                    width: 451,
                                                                    position: "relative",
                                                                    left: "-1804px",
                                                                    top: 0,
                                                                    zIndex: 999,
                                                                    opacity: 1
                                                                }}
                                                            >
                                                                <h2 className="-title">
                                                                    รับประกัน แตกง่าย จ่ายหนัก{" "}
                                                                    <br className="d-none d-md-block" />{" "}
                                                                    กับแจ็กพอตของเกมสล็อตออนไลน์
                                                                </h2>
                                                                <p className="-sub-title">
                                                                    เล่นง่าย แตกง่าย ทุกเกมไม่รู้จบ ต้องเกม สล็อตออนไลน์{" "}
                                                                    <br className="d-none d-md-block" />{" "}
                                                                    ที่นี่ที่เดียวรับประกันแตกแจกรางวัลใหญ่ทุกเกมแน่นอน
                                                                </p>
                                                                <div
                                                                    className="-btn-wrapper d-none d-lg-inline-block animated fadeInUp"
                                                                    data-animatable="fadeInUp"
                                                                    data-delay={500}
                                                                >
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="btn -register-btn order-0"
                                                                        data-toggle="modal"
                                                                        data-target="#registerModal"
                                                                        tabIndex={0}
                                                                    >
                                                                        <img
                                                                            src="/build/web/ez-bet-v2/img/btn-register-bg.png"
                                                                            className="-btn-bg"
                                                                            alt="EZ Slot สมัครสมาชิก"
                                                                            width={312}
                                                                            height={73}
                                                                        />
                                                                        <span className="-btn-title">สมัครสมาชิก</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="-status-game-section-wrapper -anon">
                        <div className="container">
                            <ul className="-status-game-container-wrapper ">
                                <li
                                    className="-status-list -wallet lazyloaded animated fadeInUp"
                                    data-bgset=""
                                    data-animatable="fadeInUp"
                                    data-delay={100}
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            data-srcset="/build/web/ez-bet-v2/img/ez-slot-status-wallet.png?v=3"
                                            srcSet="/build/web/ez-bet-v2/img/ez-slot-status-wallet.png?v=3"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            data-srcset="/build/web/ez-bet-v2/img/ez-slot-status-wallet.png?v=1"
                                            srcSet="/build/web/ez-bet-v2/img/ez-slot-status-wallet.png?v=1"
                                        />
                                        <img
                                            alt="EzSlot status list wallet"
                                            className="-status-img lazyloaded"
                                            width={80}
                                            height={80}
                                            data-src="/build/web/ez-bet-v2/img/ez-slot-status-wallet.png?v=1"
                                            src="/build/web/ez-bet-v2/img/ez-slot-status-wallet.png?v=1"
                                        />
                                    </picture>
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">Smart Wallet</h3>
                                        <span className="-status-detail">
                                            เล่นได้ทุกเกมส์
                                            <br />
                                            ไม่ต้องโยกเงิน
                                        </span>
                                    </div>
                                </li>
                                <li
                                    className="-status-list -rank lazyloaded animated fadeInUp"
                                    data-bgset=""
                                    data-animatable="fadeInUp"
                                    data-delay={200}
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            data-srcset="/build/web/ez-bet-v2/img/ez-slot-status-rank.png?v=3"
                                            srcSet="/build/web/ez-bet-v2/img/ez-slot-status-rank.png?v=3"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            data-srcset="/build/web/ez-bet-v2/img/ez-slot-status-rank.png?v=1"
                                            srcSet="/build/web/ez-bet-v2/img/ez-slot-status-rank.png?v=1"
                                        />
                                        <img
                                            alt="EzSlot status list rank"
                                            className="-status-img lazyloaded"
                                            width={80}
                                            height={80}
                                            data-src="/build/web/ez-bet-v2/img/ez-slot-status-rank.png?v=1"
                                            src="/build/web/ez-bet-v2/img/ez-slot-status-rank.png?v=1"
                                        />
                                    </picture>
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">อันดับ 1 ในไทย</h3>
                                        <span className="-status-detail">
                                            เทคโนโลยี และเกมส์
                                            <br />
                                            จากทั่วทุกมุมโลก
                                        </span>
                                    </div>
                                </li>
                                <li
                                    className="-status-list -change lazyloaded animated fadeInUp"
                                    data-bgset=""
                                    data-animatable="fadeInUp"
                                    data-delay={300}
                                >
                                    <picture>
                                        <source
                                            type="image/webp"
                                            data-srcset="/build/web/ez-bet-v2/img/ez-slot-status-change.png?v=3"
                                            srcSet="/build/web/ez-bet-v2/img/ez-slot-status-change.png?v=3"
                                        />
                                        <source
                                            type="image/png?v=1"
                                            data-srcset="/build/web/ez-bet-v2/img/ez-slot-status-change.png?v=1"
                                            srcSet="/build/web/ez-bet-v2/img/ez-slot-status-change.png?v=1"
                                        />
                                        <img
                                            alt="EzSlot status list change"
                                            className="-status-img lazyloaded"
                                            width={80}
                                            height={80}
                                            data-src="/build/web/ez-bet-v2/img/ez-slot-status-change.png?v=1"
                                            src="/build/web/ez-bet-v2/img/ez-slot-status-change.png?v=1"
                                        />
                                    </picture>
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">ฝาก - ถอน เร็วมาก</h3>
                                        <span className="-status-detail">
                                            ฝาก-ถอนเร็วมาก
                                            <br />
                                            ด้วยระบบ Bot AI
                                        </span>
                                    </div>
                                </li>
                                <li
                                    className="-status-list -eveywhere lazyloaded animated fadeInUp"
                                    data-bgset=""
                                    data-animatable="fadeInUp"
                                    data-delay={400}
                                >
                                    <picture>
                                        <img
                                            alt="EzSlot status list eveywhere"
                                            className="-status-img lazyloaded"
                                            width={80}
                                            height={80}
                                            data-src="/build/web/ez-bet-v2/img/ez-slot-status-eveywhere.webp?v=1"
                                            src="/build/web/ez-bet-v2/img/ez-slot-status-eveywhere.webp?v=1"
                                        />
                                    </picture>
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">เล่นได้ทุกที่</h3>
                                        <span className="-status-detail">
                                            ระบบใช้ง่าย
                                            <br />
                                            รองรับทุกอุปกรณ์
                                        </span>
                                    </div>
                                </li>
                                <li
                                    className="-status-list -support lazyloaded animated fadeInUp"
                                    data-bgset=""
                                    data-animatable="fadeInUp"
                                    data-delay={500}
                                >
                                    <picture>
                                        <img
                                            alt="EzSlot status list support"
                                            className="-status-img lazyloaded"
                                            width={80}
                                            height={80}
                                            data-src="/build/web/ez-bet-v2/img/ez-slot-status-support.webp?v=1"
                                            src="/build/web/ez-bet-v2/img/ez-slot-status-support.webp?v=1"
                                        />
                                    </picture>
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">บริการด้วยใจ</h3>
                                        <span className="-status-detail">
                                            ฝ่ายบริการลูกค้า
                                            <br />
                                            24 ชม ทุกวัน
                                        </span>
                                    </div>
                                </li>
                                <li
                                    className="-status-list -promotion lazyloaded animated fadeInUp"
                                    data-bgset=""
                                    data-animatable="fadeInUp"
                                    data-delay={600}
                                >
                                    <picture>
                                        <img
                                            alt="EzSlot status list promotion"
                                            className="-status-img lazyloaded"
                                            width={80}
                                            height={80}
                                            data-src="/build/web/ez-bet-v2/img/ez-slot-status-promotion.webp?v=1"
                                            src="/build/web/ez-bet-v2/img/ez-slot-status-promotion.webp?v=1"
                                        />
                                    </picture>
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">โปรดีๆสำหรับคุณ</h3>
                                        <span className="-status-detail">
                                            กิจกรรมและโปรโมชั่น
                                            <br />
                                            หลากหลายแรงที่สุดในไทย
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="-index-review-wrapper mt-5">
                        <div className="x-review-entry-index-container js-review-entry-index">
                            <div
                                className="-header-wrapper -anon js-review-form-container "
                            >
                                <div
                                    data-animatable="fadeInUp"
                                    data-delay={150}
                                    className="animated fadeInUp"
                                >

                                </div>
                                {/* <button
                                    type="button"
                                    className="btn -review-login-btn animated fadeInUp"
                                    data-toggle="modal"
                                    data-target="#loginModal"
                                    data-animatable="fadeInUp"
                                    data-delay={250}
                                >
                                    <span className="-text">กรุณาเข้าสู่ระบบ</span>
                                </button> */}
                            </div>
                            <div className="-outer-content-wrapper">
                                <div className="x-review-list-content-container">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <strong
                                                    className="-main-title animated fadeInUp"
                                                    data-animatable="fadeInUp"
                                                    data-delay={150}
                                                >
                                                    ข้อความรีวิวจากผู้ใช้จริง
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <ul className="nav -review-list">
                                                    <li
                                                        className="-review-list-item animated fadeInLeft"
                                                        data-animatable="fadeInLeft"
                                                    >
                                                        <div className="-header">
                                                            <div className="-profile-wrapper -full">
                                                                <img
                                                                    src="\build\web\ez-bet-v2\img\33a0440e8843ce7c29a937661d9c21fa.png"
                                                                    className="-profile-img"
                                                                    alt="ระบบ Review จากผู้ใช้งานจริง"
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                                <div className="-right-content">
                                                                    <div className="-top">
                                                                        <span className="-text">
                                                                            สมาชิกหมายเลข : 061-519-xxxx
                                                                        </span>
                                                                    </div>
                                                                    <div className="-bottom">
                                                                        <span className="-detail">
                                                                            IP : 2001:44c8:4601:5e1a:4126:f1a8:c596:76a2
                                                                        </span>
                                                                        <span className="-detail">
                                                                            <i className="far fa-clock -ic" /> 27-06-2023 15:36
                                                                            น.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-star-rate-wrapper -small">
                                                                <div className="rating-container rating-xs rating-animate rating-disabled">
                                                                    <div className="rating-stars">
                                                                        <span className="empty-stars">
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="filled-stars"
                                                                            style={{ width: "100%" }}
                                                                        >
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <input
                                                                            type="hidden"
                                                                            defaultValue={5}
                                                                            data-star-rating='{"step":1}'
                                                                            readOnly="readonly"
                                                                            className="rating-input"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="-description">
                                                            <p className="x-review-comment-rendered">
                                                                ขอบคุณครับที่จัดกิจกรรมดีๆไห้ทุกวัน
                                                                ไม่มากเเต่มันเป็นโอกาศให้นักสู้อย่างเราได้ไปต่อ
                                                                เเอดมินก็พูดตาน่ารักมากๆ ระบบก็นิ่ง เกมส์มีครบจบทุกค่าย
                                                                เล่นได้ก็เอาไปเลยเท่าไหร่ก็จ่าย
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        className="-review-list-item animated fadeInLeft"
                                                        data-animatable="fadeInLeft"
                                                    >
                                                        <div className="-header">
                                                            <div className="-profile-wrapper -full">
                                                                <img
                                                                    src="\build\web\ez-bet-v2\img\33a0440e8843ce7c29a937661d9c21fa.png"
                                                                    className="-profile-img"
                                                                    alt="ระบบ Review จากผู้ใช้งานจริง"
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                                <div className="-right-content">
                                                                    <div className="-top">
                                                                        <span className="-text">
                                                                            สมาชิกหมายเลข : 063-104-xxxx
                                                                        </span>
                                                                    </div>
                                                                    <div className="-bottom">
                                                                        <span className="-detail">
                                                                            IP : 2001:44c8:4601:5e1a:4126:f1a8:c596:76a2
                                                                        </span>
                                                                        <span className="-detail">
                                                                            <i className="far fa-clock -ic" /> 27-06-2023 15:35
                                                                            น.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-star-rate-wrapper -small">
                                                                <div className="rating-container rating-xs rating-animate rating-disabled">
                                                                    <div className="rating-stars">
                                                                        <span className="empty-stars">
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="filled-stars"
                                                                            style={{ width: "100%" }}
                                                                        >
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <input
                                                                            type="hidden"
                                                                            defaultValue={5}
                                                                            data-star-rating='{"step":1}'
                                                                            readOnly="readonly"
                                                                            className="rating-input"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="-description">
                                                            <p className="x-review-comment-rendered">
                                                                รีวิวจากนักปั่นตัวจริงอย่างผม บอกได้เลยว่าเวฌปนี้ไม่ธรรมดา
                                                                ไม่ว่าจะเป็นเรื่องบริการจากเเอดมิน หรือระบบฝากถอนออโต
                                                                จัดได้ว่าดี รวดเร็ว&lt;br /&gt;
                                                                <br />
                                                                อีกทั้งกิจกรรมต่างๆที่ช่วยสร้างโอกาสไห้นักปั่นได้มีโอกาสได้เเก้ตัว
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        className="-review-list-item animated fadeInLeft"
                                                        data-animatable="fadeInLeft"
                                                    >
                                                        <div className="-header">
                                                            <div className="-profile-wrapper -full">
                                                                <img
                                                                    src="\build\web\ez-bet-v2\img\33a0440e8843ce7c29a937661d9c21fa.png"
                                                                    className="-profile-img"
                                                                    alt="ระบบ Review จากผู้ใช้งานจริง"
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                                <div className="-right-content">
                                                                    <div className="-top">
                                                                        <span className="-text">
                                                                            สมาชิกหมายเลข : 065-464-xxxx
                                                                        </span>
                                                                    </div>
                                                                    <div className="-bottom">
                                                                        <span className="-detail">
                                                                            IP : 2001:44c8:4601:5e1a:4126:f1a8:c596:76a2
                                                                        </span>
                                                                        <span className="-detail">
                                                                            <i className="far fa-clock -ic" /> 27-06-2023 15:34
                                                                            น.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-star-rate-wrapper -small">
                                                                <div className="rating-container rating-xs rating-animate rating-disabled">
                                                                    <div className="rating-stars">
                                                                        <span className="empty-stars">
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="filled-stars"
                                                                            style={{ width: "100%" }}
                                                                        >
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <input
                                                                            type="hidden"
                                                                            defaultValue={5}
                                                                            data-star-rating='{"step":1}'
                                                                            readOnly="readonly"
                                                                            className="rating-input"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="-description">
                                                            <p className="x-review-comment-rendered">
                                                                ขอบคุณครับที่จัดกิจกรรมดีๆไห้ทุกวัน
                                                                ไม่มากเเต่มันเป็นโอกาศให้นักสู้อย่างเราได้ไปต่อ
                                                                เเอดมินก็พูดตาน่ารักมากๆ ระบบก็นิ่ง เกมส์มีครบจบทุกค่าย
                                                                เล่นได้ก็เอาไปเลยเท่าไหร่ก็จ่าย
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        className="-review-list-item animated fadeInLeft"
                                                        data-animatable="fadeInLeft"
                                                    >
                                                        <div className="-header">
                                                            <div className="-profile-wrapper -full">
                                                                <img
                                                                    src="\build\web\ez-bet-v2\img\33a0440e8843ce7c29a937661d9c21fa.png"
                                                                    className="-profile-img"
                                                                    alt="ระบบ Review จากผู้ใช้งานจริง"
                                                                    width={100}
                                                                    height={100}
                                                                />
                                                                <div className="-right-content">
                                                                    <div className="-top">
                                                                        <span className="-text">
                                                                            สมาชิกหมายเลข : 098-818-xxxx
                                                                        </span>
                                                                    </div>
                                                                    <div className="-bottom">
                                                                        <span className="-detail">
                                                                            IP : 2001:44c8:4222:66c5:cd3:9113:6ae0:f636
                                                                        </span>
                                                                        <span className="-detail">
                                                                            <i className="far fa-clock -ic" /> 27-06-2023 15:33
                                                                            น.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-star-rate-wrapper -small">
                                                                <div className="rating-container rating-xs rating-animate rating-disabled">
                                                                    <div className="rating-stars">
                                                                        <span className="empty-stars">
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="filled-stars"
                                                                            style={{ width: "100%" }}
                                                                        >
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                            <span className="star">
                                                                                <i className="fas fa-star" />
                                                                            </span>
                                                                        </span>
                                                                        <input
                                                                            type="hidden"
                                                                            defaultValue={5}
                                                                            data-star-rating='{"step":1}'
                                                                            readOnly="readonly"
                                                                            className="rating-input"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="-description">
                                                            <p className="x-review-comment-rendered">
                                                                ฝากถอนเร็วมากเกมลืนไม่กระตุกมีแอดมินบริการดีรวดเร็วทันใจ
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            className="text-center mt-4 animated fadeInUp"
                                            data-animatable="fadeInUp"
                                        >
                                            <a href="reviews.html" className="btn -btn-view">
                                                <span className="-btn-text">ดูรีวิวทั้งหมด</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <nav className="fixnav-bar">
                    <div className="container">
                        <ul className="fixnav">
                            <li className="fixnav-item hvr-pulse-grow">
                                <a href="/promotions" className="fixnav-link">
                                    <i className="far fa-gift" /> <span>โปรโมชั่น</span>
                                </a>
                            </li>
                            {(loginstate ? <li className="fixnav-item hvr-pulse-grow">
                                <a href="#deposit-choose-promotion" data-toggle="modal" data-target="#depositChoosePromotionModal" className="fixnav-link">
                                    <i className="far fa-play" /> <span>ฝากเงิน</span>
                                </a>
                            </li> : <li className="fixnav-item hvr-pulse-grow">
                                <a href="/register" className="fixnav-link">
                                    <img src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png" className="-icon" alt="SA Casino phone icon" width="10" height="14" /> <span>สมัคร</span>
                                </a>
                            </li>)}

                            <li className="fixnav-item middle-item hvr-bounce-in">
                                <a
                                    href="/"
                                    className="fixnav-link active"
                                    aria-current="page"
                                >
                                    <i className="fas fa-dice-six" /> <span>เข้าเล่น</span>
                                </a>
                            </li>
                            <li className="fixnav-item hvr-pulse-grow">
                                {(loginstate ? <li className="fixnav-item hvr-pulse-grow">
                                    <a href="#deposit-choose-promotion" data-toggle="modal" data-target="#withdrawModal" className="fixnav-link">
                                        <i className="far fa-play" /> <span>ถอนเงิน</span>
                                    </a>
                                </li> : <li className="fixnav-item hvr-pulse-grow">
                                    <a data-target="#loginModal" data-toggle="modal" data-dismiss="modal" className="fixnav-link">
                                        <img src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png" className="-icon" alt="SA Casino phone icon" width="10" height="14" /> <span>เข้าสู่ระบบ</span>
                                    </a>
                                </li>)}
                            </li>
                            <li className="fixnav-item hvr-pulse-grow">
                                <a href={this.state.linelink} className="fixnav-link">
                                    <i className="fab fa-line" /> <span>ติดต่อ</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav >
            </>
        );
    }
}

export default Main;
